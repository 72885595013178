import React from "react";

import "./ItemCard.scss";
import PalmOil from "../../images/drop.jpg";
import Shortening from "../../images/shortening.jpg";
import JerryCans from "../../images/can.jpg";
import BagInBox from "../../images/bag.jpg";
import FlexiBag from "../../images/flexibag.jpg";
import Soap from "../../images/soap.jpg";

export default function ItemCards() {
  const itemCards = [
    { image: PalmOil, name: "Palm Oil" },
    { image: Shortening, name: "Shortening" },
    { image: JerryCans, name: "Jerrycans" },
    { image: BagInBox, name: "Bag in Box" },
    { image: FlexiBag, name: "Flexibag" },
    { image: Soap, name: "Soap Noodles" },
  ];

  const itemCardsList = itemCards.map((item, index) => {
    return (
      <div className="item-card" key={index}>
        <div className="item-card-image-container ">
          <img
            src={item.image}
            className="item-card-image-resize"
            alt="products"
          />
          <p className="item-card-text">{item.name}</p>
        </div>
      </div>
    );
  });

  return (
    <div className="item-card-container">
      <div className="item-card-title">
        <p>Our Product Line-Up</p>
      </div>
      <div className="item-card-wrapper">{itemCardsList}</div>
      <hr />
    </div>
  );
}
