import React from "react";
import Slider from "nuka-carousel";

import imageOne from "../../images/sumavigroup ui-05.png";
import imageTwo from "../../images/sumavigroup ui-06.png";
import imageThree from "../../images/sumavigroup ui-07.png";
import "./Carousel.scss";

export default function Carousel() {
  const image = [imageOne, imageTwo, imageThree];

  const imageList = image.map((item, index) => (
    <div className="image-container" key={index}>
      <img src={item} alt="" className="image-resize " />
    </div>
  ));

  const scrollTo = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  const isMobile = window.innerWidth < 768;

  console.log(isMobile);
  return (
    <div className="carousel-container">
      <Slider
        wrapAround={true}
        autoplay={true}
        autoplayInterval={3000}
        transitionMode={"scroll"}
        defaultControlsConfig={{
          nextButtonText: ">",
          prevButtonText: "<",
          pagingDotsStyle: {
            fill: "white",
          },
        }}
      >
        {imageList}
      </Slider>
      <div className="carousel-text-container ">
        {isMobile ? (
          <>
            <p className="carousel-heading-text">
              <b>
                YOUR <br />
                HEALTHY
                <br />
                FOOD
                <br />
                BEGINS
                <br />
                HERE
              </b>
            </p>
            <p className="carousel-text">
              Sumavi Group was conceived as a palm oil company in Malaysia to
              supply quality and healthy palm oil products. we actively
              contribute to the supply of high quality and reliable palm oil
              products to everyone
            </p>
            <button
              type="button"
              className="btn btn-danger button-custom "
              onClick={scrollTo}
            >
              Learn More
            </button>
          </>
        ) : (
          <>
            <p className="carousel-heading-text">
              <b>
                YOUR HEALTHY FOOD
                <br /> BEGINS HERE
              </b>
            </p>
            <p className="carousel-text">
              Sumavi Group was conceived as a palm oil company in Malaysia to
              supply quality and healthy palm oil products. we actively
              contribute to the supply of high quality and reliable palm oil
              products to everyone
            </p>
            <button
              type="button"
              className="btn btn-danger button-custom "
              onClick={scrollTo}
            >
              Learn More
            </button>
          </>
        )}
      </div>
    </div>
  );
}
