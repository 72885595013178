import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./FlagCarousel.scss";
import Flag01 from "../../images/flag01.svg";
import Flag02 from "../../images/flag02.svg";
import Flag03 from "../../images/flag03.svg";
import Flag04 from "../../images/flag04.svg";
import Flag05 from "../../images/flag05.svg";
import Flag06 from "../../images/flag06.svg";
import Flag07 from "../../images/flag07.svg";
import Flag08 from "../../images/flag08.svg";
import Flag09 from "../../images/flag09.svg";
import Flag10 from "../../images/flag10.svg";
import Flag11 from "../../images/flag11.svg";

export default function FlagCarousel() {
  const images = [
    Flag01,
    Flag02,
    Flag03,
    Flag04,
    Flag05,
    Flag06,
    Flag07,
    Flag08,
    Flag09,
    Flag10,
    Flag11,
  ];

  const isMobile = window.innerWidth < 768;

  const imageList = images.map((item, index) => (
    <div className="flag-slider-image-container" key={index}>
      <img src={item} alt="flags" />
    </div>
  ));
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 3 : 6,
  };
  return (
    <div className="slider-container-flag">
      <p>We have exported our palm oil products to more than 10 countries</p>
      <Slider {...settings}>{imageList}</Slider>
    </div>
  );
}
