import React from "react";
import "./Footer.scss";

import Gps from "../../images/gps.svg";
import Internet from "../../images/Internet.svg";
import Phone from "../../images/phone.svg";

export default function Footer() {
  const isMobile = window.innerWidth < 768;
  return (
    <div className="footer-image-container">
      <div className="footer-container">
        <div className="footer-left-container">
          <div>
            <p className="footer-title">
              <span className="footer-title-sumavi">SUMAVI</span> GROUP
            </p>
            <p className="footer-subtitle">Get in touch with us</p>
            <div className="footer-left-flex">
              <div className="footer-icon">
                <img src={Gps} className="footer-image-resize" alt="gps" />
              </div>
              <div>
                <p className="footer-text ">Vind Asia International Pte Ltd,</p>
                <p className="footer-text ">10 Anson Road, Level 26-10,</p>
                <p className="footer-text ">International Plaza,</p>
                <p className="footer-text ">Singapore - 079903</p>
              </div>
            </div>
          </div>
          {isMobile ? (
            <div className="footer-top-padding">
              <div className="footer-left-flex">
                <div className="footer-icon">
                  <img
                    src={Phone}
                    className="footer-image-resize"
                    alt="phone"
                  />
                </div>
                <div>
                  <p className="footer-text ">+6568297218</p>
                </div>
              </div>
              <div className="footer-left-flex">
                <div className="footer-icon">
                  <img
                    src={Internet}
                    className="footer-image-resize"
                    alt="Internet"
                  />
                </div>
                <div>
                  <p className="footer-text ">info@sumavigroup.com</p>
                  <p className="footer-text ">www.sumavigroup.com</p>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="footer-left-flex">
                <div className="footer-icon">
                  <img
                    src={Phone}
                    className="footer-image-resize"
                    alt="phone"
                  />
                </div>
                <div>
                  <p className="footer-text ">+6568297218</p>
                </div>
              </div>
              <div className="footer-left-flex">
                <div className="footer-icon">
                  <img
                    src={Internet}
                    className="footer-image-resize"
                    alt="Internet"
                  />
                </div>
                <div>
                  <p className="footer-text ">info@sumavigroup.com</p>
                  <p className="footer-text ">www.sumavigroup.com</p>
                </div>
              </div>
            </>
          )}
        </div>
        {/* <div className="footer-right-container">
          <p className="footer-title">
            <span className="footer-title-sumavi">NEWS LETTER</span>
          </p>
          <p className="footer-text-right">
            Get the latest news on our products & servieces. Subscribe Now
          </p>
          <input className="footer-input" />
          <button className="footer-button">SUBSCRIBE NOW</button>
        </div> */}
      </div>
    </div>
  );
}
