import React from "react";

import "./KeyPoints.scss";
import Flexible from "../../images/sumavigroup ui-06.svg";
import Delivery from "../../images/sumavigroup ui-07.svg";
import Storage from "../../images/sumavigroup ui-08.svg";
import Quality from "../../images/sumavigroup ui-09.svg";
import Business from "../../images/sumavigroup ui-10.svg";
import Stock from "../../images/sumavigroup ui-11.svg";

export default function KeyPoints() {
  const KeyPoints = [
    { image: Flexible, name: "Flexible" },
    { image: Delivery, name: "Delivery Assured" },
    { image: Storage, name: "Storage Quality" },
    { image: Stock, name: "Stock Keeping" },
    { image: Business, name: "Business Deal" },
    { image: Quality, name: "Quality Assured" },
  ];
  const scrollTo = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  const KeyPointsList = KeyPoints.map((item, index) => {
    return (
      <div className="keypoint-container" key={index}>
        <div className="keypoint-logo-container">
          <img
            src={item.image}
            className="keypoint-logo-resize"
            alt="keypoints"
          />
        </div>
        <div>
          <p className="keypoint-text">{item.name}</p>
        </div>
      </div>
    );
  });

  return (
    <div className="key-image-container">
      <div className="keypoint-wrapper">
        <div className="keypoint-container-margin">{KeyPointsList}</div>
        <div className="keypoint-button-container">
          <button
            type="button"
            className="btn btn-danger keypoint-button-custom "
            onClick={scrollTo}
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
}
