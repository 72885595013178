import React from "react";

import "./Header.scss";

export default function Header() {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light fixed-top background"
      id="mainNav"
    >
      <div className="container-fluid pt-2">
        <p className="header-title">
          <b>SUMAVI</b> GROUP
        </p>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <a className="nav-link header-text" href="index.html">
                Home
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link header-text" href="contact.html">
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link header-text" href="contact.html">
                Products
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link header-text" href="contact.html">
                Contact Us
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}
