import React from "react";

import "./Content.scss";
import HandImage from "../../images/sumavigroup ui-08.png";

export default function Content() {
  return (
    <div className="content-container">
      <div className="content-left-container">
        <p className="content-heading">
          Leading Palm Oil Trading Companies In Malaysia
        </p>
        <hr />
        <p className="content-subheading">WHAT WE CAN DO FOR YOU!</p>
        <div className="hand-image-container">
          <img className="hand-image-resize" src={HandImage} alt="Hand " />
        </div>
      </div>
      <div className="content-right-container">
        <div className="content-text">
          <p>
            With corporate tagline “Your Healthy Food Begins Here”, we
            contribute for the supply of high quality palm oil products to
            everyone.
          </p>
          <p>
            Sumavi Group was conceived as a palm oil company in Malaysia to
            supply quality and healthy palm oil products. With our corporate
            tagline “Your Healthy Food Begins Here”, we actively contribute to
            the supply of high quality and reliable palm oil products to
            everyone. We believe that success roots on our genuine sincerity and
            care in our products and customers. We value our customers and
            aspire to provide the best quality products to build a sustainable
            business relationship. Currently, palm oil trading companies in
            Malaysia are accounting for more than a third of the world’s palm
            oil supply.
          </p>
          <p>
            From conception, we have established ourselves as one of the most
            reputable and reliable palm oil trading companies in Malaysia.
            Sumavi Group supplies a comprehensive range of palm oil products
            under our in-house brands, “SUMAVI GROUP”. We source the finest raw
            materials certified by Buatan Malaysia and JAKIM to ensure that our
            products are high quality and safe for consumption.
          </p>
          <p>
            Our products are supplied in various packaging types to cater for
            the different needs of various industries ranging from industrial to
            retail. Our palm oil products are all certified HALAL by the Islamic
            Development Department of Malaysia (JAKIM) for quality assurance.
            Besides that, we have expanded product range that includes
            packaging, jerrycans and flexibags.
          </p>
          <p>
            As a palm oil company in Malaysia, we are privileged to be part of
            the value chain in the palm oil industry. This has been a vital
            hallmark in positioning Malaysia as a major producer and exporter of
            palm oil in the world. As palm oil is an important constituent of
            human food, we will continue to leverage our best business model
            practice to sustain a positive long-term prospect of the palm oil
            industry.
          </p>
        </div>
      </div>
    </div>
  );
}
