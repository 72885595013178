import React from "react";
import "./App.scss";

import Header from "./components/header/Header";
import Carousel from "./components/carousel/Carousel";
import Content from "./components/content/Content";
import KeyPoints from "./components/keyPoints/KeyPoints";
import ItemCards from "./components/itemCards/ItemCards";
import FlagCarousel from "./components/flagCarousel/FlagCarousel";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <>
      <div className="container-fluid body-padding ">
        <Header />
        <Carousel />
        <Content />
        <KeyPoints />
        <ItemCards />
        <FlagCarousel />
        <Footer />
      </div>
    </>
  );
}

export default App;
